import { environment } from '../environments/environment';

export const BASE_API_URL = `${environment.baseApi}/api`;
export const UPLOAD_FILE_API_URL = `${environment.apiUploadFile}/api`;
export const APP_CODE_UPLOAD = 'dfi';
export const API_VERSION_1 = 'v1';
export const CACHE_URL = 'isCacheUrl=true';
export const CRYPTO_KEY = 'UmZValhuMnI1dTh4IUElRCpHLUthUGRTZ1ZrWXAzczY=';
export const CRYPTO_VI = 'JUMqRi1KYU5kUmdVa1huMg==';
export const API_SERVICES = {
  ACCOUNTS: {
    AUTHENTICATE: `${BASE_API_URL}/${API_VERSION_1}/accounts/login-security`,
    CACHE_PERMISSIONS: `${BASE_API_URL}/${API_VERSION_1}/accounts/:id/permissions`,
    REGISTER: `${BASE_API_URL}/${API_VERSION_1}/accounts`,
    REGISTER_BY_EMPLOYEE: `${BASE_API_URL}/${API_VERSION_1}/accounts/generate-from-employee`,
    LOGOUT: `${BASE_API_URL}/${API_VERSION_1}/accounts/logout`,
    PERMISSIONS: `${BASE_API_URL}/${API_VERSION_1}/accounts/:id/permissions`,
    GET_ALL: `${BASE_API_URL}/${API_VERSION_1}/accounts`,
    GET_GENDER: `${BASE_API_URL}/${API_VERSION_1}/accounts/gender`,
    PUT: `${BASE_API_URL}/${API_VERSION_1}/accounts`,
    RESET_PWD: `${BASE_API_URL}/${API_VERSION_1}/accounts/reset-password-by-userid`,
    RESET_PWD_MANUAL: `${BASE_API_URL}/${API_VERSION_1}/accounts/reset-password`,
    GET_BY_ID: `${BASE_API_URL}/${API_VERSION_1}/accounts/:id`,
    UPDATE_USER: `${BASE_API_URL}/${API_VERSION_1}/accounts`,
    UPDATE_PROFILE: `${BASE_API_URL}/${API_VERSION_1}/accounts/:id/profile`,
    CHANGE_PASSWORD: `${BASE_API_URL}/${API_VERSION_1}/accounts/:id/change-password`,
    CHANGE_STATUS: `${BASE_API_URL}/${API_VERSION_1}/accounts/lock`,
    ASSIGN_PROJECT: `${BASE_API_URL}/${API_VERSION_1}/accounts/assign-project`,
    ASSIGN_PROJECT_FOR_USER: `${BASE_API_URL}/${API_VERSION_1}/accounts/assigned-project/user`,
    CHANGE_ROLES: `${BASE_API_URL}/${API_VERSION_1}/accounts/:id/change-roles`,
  },
  POSITIONS: {
    GET_ALL: `${BASE_API_URL}/${API_VERSION_1}/positions/paging`,
  },
  PAGING: 'paging',
  CHANGE_STATUS: 'change-status',
  CHANGE_STATUS_VIEW_WEB: 'change-status-view-web',
  VIEW_DETAIL: 'view-detail',
  SEND_EMAIL: 'send-email',
  CONFIGS: `${BASE_API_URL}/${API_VERSION_1}/configurations`,
  CONFIGS_ALL: `${BASE_API_URL}/${API_VERSION_1}/configurations/all`,
  UPLOAD_IMAGE: `${UPLOAD_FILE_API_URL}/${API_VERSION_1}/files/upload/image`,
  UPLOAD_DOCS: `${UPLOAD_FILE_API_URL}/${API_VERSION_1}/files/upload/docs`,
  UPDATE_DOCS: `${UPLOAD_FILE_API_URL}/${API_VERSION_1}/files`,
  VIEW_ALL_PRODUCT: `${BASE_API_URL}/${API_VERSION_1}/products/view-paging`,
  VIEW_ALL_NEWS: `${BASE_API_URL}/${API_VERSION_1}/articles/view-paging`,
  REQUEST_BUY: `${BASE_API_URL}/${API_VERSION_1}/request-buy-investment-packages`,
  PROJECTS: {
    BASE: `${BASE_API_URL}/${API_VERSION_1}/projects`,
    PAGING: `${BASE_API_URL}/${API_VERSION_1}/projects/paging`,
    ALL: `${BASE_API_URL}/${API_VERSION_1}/projects`,
  },
  ROLES: {
    GET_ALL: `${BASE_API_URL}/${API_VERSION_1}/roles`,
    POST: `${BASE_API_URL}/${API_VERSION_1}/roles`,
    PUT: `${BASE_API_URL}/${API_VERSION_1}/roles`,
    DELETE: `${BASE_API_URL}/${API_VERSION_1}/roles/:id`,
    DELETE_MULTI: `${BASE_API_URL}/${API_VERSION_1}/roles/delete-multi`,
    ACTIVE_PERMISSION: `${BASE_API_URL}/${API_VERSION_1}/roles/:id/active-permission?permissionId=:idper`,
    DE_ACTIVE_PERMISSION: `${BASE_API_URL}/${API_VERSION_1}/roles/:id/deactive-permission?permissionId=:idper`,
    GET_PERMISSIONS: `${BASE_API_URL}/${API_VERSION_1}/roles/:id/permissions`,
    ADD_USERS: `${BASE_API_URL}/${API_VERSION_1}/roles/:id/add-users`,
    GET_USERS: `${BASE_API_URL}/${API_VERSION_1}/roles/:id/users`,
    REMOVE_USERS: `${BASE_API_URL}/${API_VERSION_1}/roles/:id/remove-users`,
    ASSIGN_PERMISSION: `${BASE_API_URL}/${API_VERSION_1}/roles/:id/assign-permissions`,
  },
  SETTINGS: {
    BY_GROUP: `${BASE_API_URL}/${API_VERSION_1}/settings/by-group?${CACHE_URL}`,
  },
  PERMISSIONS: {
    GET_ALL: `${BASE_API_URL}/${API_VERSION_1}/roles`,
    POST: `${BASE_API_URL}/${API_VERSION_1}/roles`,
    PUT: `${BASE_API_URL}/${API_VERSION_1}/roles`,
    DELETE: `${BASE_API_URL}/${API_VERSION_1}/roles?id=:id`,
    GET_PERMISSIONS: `${BASE_API_URL}/${API_VERSION_1}/Permissions/:id/role-permission`,
    GET_GROUP_PERMISSIONS: `${BASE_API_URL}/${API_VERSION_1}/Permissions/group-permissions`,
  },
  EMPLOYEES: {
    IMPORT: `${BASE_API_URL}/${API_VERSION_1}/employees/import`,
    EXPORT_TEMPLATE: `${BASE_API_URL}/${API_VERSION_1}/employees/download-template`,
  },
  CONFIGURATIONS: {
    GET_BY_CODE: `${BASE_API_URL}/${API_VERSION_1}/configurations/code`,
    GET_BY_TYPE: `${BASE_API_URL}/${API_VERSION_1}/configurations/type`,
  },
  DEPARTMENTS: {
    PARENT: `${BASE_API_URL}/${API_VERSION_1}/departments/:id/parent`,
    ALL: `${BASE_API_URL}/${API_VERSION_1}/departments/all`,
    ALL_MASTER: `${BASE_API_URL}/${API_VERSION_1}/departments/all-master`,
    CURRENT_USER: `${BASE_API_URL}/${API_VERSION_1}/departments/current-user`,
  },
  CONTRACTS: {
    UPLOAD: `${BASE_API_URL}/${API_VERSION_1}/contracts/upload`,
    UPLOAD_MULTIPLE: `${BASE_API_URL}/${API_VERSION_1}/contracts/upload-multiple`,
    DELETE_ATTACHMENT: `${BASE_API_URL}/${API_VERSION_1}/contracts/attachments/:id`,
    CONTRACT_ATTACHMENTS: `${BASE_API_URL}/${API_VERSION_1}/contracts/:id/attachments`,
    CONTRACT_ATTACHMENT_UPLOAD: `${BASE_API_URL}/${API_VERSION_1}/contracts/upload`,
    CONTRACT_ATTACHMENT_DELETE: `${BASE_API_URL}/${API_VERSION_1}/contracts/attachments/:id`,
    CONTRACT_ATTACHMENTS_SUMMARY: `${BASE_API_URL}/${API_VERSION_1}/contracts/:id/attachments/summary`,
    CONTRACT_DETAILS_SUMMARY: `${BASE_API_URL}/${API_VERSION_1}/contracts/:id/details/summary`,
    CONTRACT_ATTACHMENT_GET: `${BASE_API_URL}/${API_VERSION_1}/contracts/attachments/:id`,
  },
  INVOICES: {
    UPLOAD: `${BASE_API_URL}/${API_VERSION_1}/invoices/upload`,
    UPLOAD_MULTIPLE: `${BASE_API_URL}/${API_VERSION_1}/invoices/upload-multiple`,
    DELETE_ATTACHMENT: `${BASE_API_URL}/${API_VERSION_1}/invoices/attachments/:id`,
  },
  PROPOSALS: {
    EXPORT_ITEM: `${BASE_API_URL}/${API_VERSION_1}/proposals/export/:id`,
    EXPORT_LIST: `${BASE_API_URL}/${API_VERSION_1}/proposals/export`,
    SUBMIT: `${BASE_API_URL}/${API_VERSION_1}/proposals/:id/submit`,
    PAY: `${BASE_API_URL}/${API_VERSION_1}/proposals/:id/pay`,
    CANCEL: `${BASE_API_URL}/${API_VERSION_1}/proposals/:id/cancel`,
    ACCOUNTANT_APPROVE: `${BASE_API_URL}/${API_VERSION_1}/proposals/:id/accountant-approve`,
    ACCOUNTANT_REJECT: `${BASE_API_URL}/${API_VERSION_1}/proposals/:id/accountant-reject`,
    FINANCIAL_APPROVE: `${BASE_API_URL}/${API_VERSION_1}/proposals/:id/financial-approve`,
    FINANCIAL_REJECT: `${BASE_API_URL}/${API_VERSION_1}/proposals/:id/financial-reject`,
    APPROVAL_PAGING: `${BASE_API_URL}/${API_VERSION_1}/proposals/:id/approval-paging`,
    PROPOSAL_ATTACHMENTS: `${BASE_API_URL}/${API_VERSION_1}/proposals/:id/attachments`,
    PROPOSAL_PAYMENT_SUMMARY: `${BASE_API_URL}/${API_VERSION_1}/proposals/:id/payment-summary`,
    PROPOSAL_ATTACHMENT_UPLOAD: `${BASE_API_URL}/${API_VERSION_1}/proposals/upload`,
    PROPOSAL_ATTACHMENT_DELETE: `${BASE_API_URL}/${API_VERSION_1}/proposals/attachments/:id`,
    CREATE_PROPOSAL: `${BASE_API_URL}/${API_VERSION_1}/proposals/create?contractId=:contractId&batchNumber=:batchNumber`,
  },
  PROPOSAL_PAYMENTS: {
    DELETE: `${BASE_API_URL}/${API_VERSION_1}/proposal-payments/:id`,
    CREATE: `${BASE_API_URL}/${API_VERSION_1}/proposal-payments`,
    UPDATE: `${BASE_API_URL}/${API_VERSION_1}/proposal-payments`,
    DETAIL: `${BASE_API_URL}/${API_VERSION_1}/proposal-payments/:id`,
    PAGING: `${BASE_API_URL}/${API_VERSION_1}/proposal-payments/:id/paging`,
    PAGING_BY_CONTRACT: `${BASE_API_URL}/${API_VERSION_1}/proposal-payments/paging-by-contract?contractId=:contractId&batchNumber=:batchNumber`,
    EXPORT_BY_CONTRACT: `${BASE_API_URL}/${API_VERSION_1}/proposal-payments/export-by-contract?contractId=:contractId&batchNumber=:batchNumber`,
   
  },
  CONTRACT_TYPES: {
    ALL: `${BASE_API_URL}/${API_VERSION_1}/contract-types`,
  },
  COST_TYPES: {
    ALL: `${BASE_API_URL}/${API_VERSION_1}/cost-types`,
  },
  FINANCIAL_CODES: {
    ALL: `${BASE_API_URL}/${API_VERSION_1}/financial-codes`,
    FINANCIAL_ITEM_TYPES: `${BASE_API_URL}/${API_VERSION_1}/financial-codes/financial-item-types`,
    FINANCIAL_ACTIVITY_TYPES: `${BASE_API_URL}/${API_VERSION_1}/financial-codes/financial-activity-types`,
  },
  ATTACHMENT_TYPES: {
    ALL: `${BASE_API_URL}/${API_VERSION_1}/attachment-types`,
  },
  COMPANIES: {
    ALL: `${BASE_API_URL}/${API_VERSION_1}/companies`,
  },
  BUDGET_PLAN_ADJUSTMENTS: {
    APPROVE: `${BASE_API_URL}/${API_VERSION_1}/budget-plan-adjustments/:id/approve`,
    APPROVE_MULTIPLE: `${BASE_API_URL}/${API_VERSION_1}/budget-plan-adjustments/approve-multiple`,
    REJECT: `${BASE_API_URL}/${API_VERSION_1}/budget-plan-adjustments/:id/reject`,
  },
  BUDGET_PLANS: {
    BUDGET_PLAN_ATTACHMENTS: `${BASE_API_URL}/${API_VERSION_1}/budget-plans/:id/attachments`,
    BUDGET_PLAN_ATTACHMENT_UPLOAD: `${BASE_API_URL}/${API_VERSION_1}/budget-plans/upload`,
    BUDGET_PLAN_ATTACHMENT_DELETE: `${BASE_API_URL}/${API_VERSION_1}/budget-plans/attachments/:id`,
    BUDGET_PLANS_BY_FINANCIAL_CODE_ID: `${BASE_API_URL}/${API_VERSION_1}/budget-plans/paging-by-financial?financialCodeId=:financialCodeId`,
  },
  DAILY_REPORTS: {
    PAGING_BY_DATE: `${BASE_API_URL}/${API_VERSION_1}/daily-reports/paging-by-date`,
    EXPORT_BY_DATE: `${BASE_API_URL}/${API_VERSION_1}/daily-reports/export-by-date`,
  },
};
